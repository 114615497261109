.Checkbox-Label {
    font-family: var(--typography-font-family-regular);
    cursor: pointer;
    color: var(--color-on-surface-high-emphasis);
    white-space: normal;
    margin-left: 12px;
    line-height: 1;
}

.Checkbox_disabled .Checkbox-Label {
    cursor: default;
} 

