.Table_view_default {
    color: rgba(255, 255, 255, 0.87);
    border-collapse: collapse; 
    overflow: hidden;
    background: var(--color-elevation-overlay-01);
    
}
.Table_view_default .Table-Row {
    border-bottom: 1px solid var(--color-unsorted-background);
}

.Table_view_default .Table-HeadCell {
    color: var(--color-on-surface-disabled);
}