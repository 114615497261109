.Badge.Badge_view_warning {
    color: var(--color-status-yellow-high);
    background: var(--color-status-yellow-low);
}

.Badge_view_warning.Badge_hovered:hover {
    cursor: pointer;
}

.Badge_view_warning.Badge_hovered:hover:before {
    background: var(--color-white-hover);
}

.Badge_view_warning.Badge_disabled {
    color: var(--color-status-yellow-disabled);
}

.Badge_view_warning.Badge_disabled:hover {
    cursor: default;
}

.Badge_view_warning.Badge_disabled:hover:before {
    background: none;
}