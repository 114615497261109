.Table_view_transparent {
    color: rgba(255, 255, 255, 0.87);
    border-collapse: collapse;
    overflow: hidden;
    background: 'transparent';
}

.Table_view_transparent .Table-Row {
    
    border-bottom: 1px solid var(--color-on-surface-white-12);
}

.Table_view_transparent .Table-Row:last-child,
.Table_view_transparent .Table-Row:hover {
    border-bottom: none;
}

.Table_view_transparent .Table-Head {
    color: var(--color-on-surface-disabled);
    border-bottom: 1px solid var(--color-on-surface-white-12);
}

.Table_view_transparent .Table-Head:hover {
    border-bottom: none;
}