.TextField-Icon {
    color: var(--color-on-surface-medium-emphasis);
    pointer-events: none;
    height: 100%;
}

.TextField_readOnly .TextField-Icon,
.TextField_disabled .TextField-Icon {
    color: var(--color-on-surface-disabled);
}

.TextField-Icon_side_left {
    margin-left: 12px;
}

.TextField-Icon_side_right {
    margin-right: 12px;
}

.TextField-Icon_clickable {
    pointer-events: auto;
    margin-right: 12px;
    cursor: pointer;
}
