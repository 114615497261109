.Checkbox_size_s .Checkbox-Box {
    width: 16px;
    height: 16px;
    min-width: 16px;
}

.Checkbox_size_s .Checkbox-Tick svg {
    width: 17px;
    height: 17px;
}

.Checkbox_size_s .Checkbox-Label {
    margin-left: 10px;
    font-size: var(--typography-font-size-s-font-size);
}

.Checkbox_size_s .Checkbox-Box:hover::after {
    width: 32px;
    height: 32px;
}