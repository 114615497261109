.Card_view_light {
    padding: var(--space-m);
    background: var(--color-elevation-overlay-24);
}


.Card_view_light.Card:hover {
    background: var(--color-elevation-overlay-24);
}

.Card_view_light.Card_selected {
    background: var(--color-elevation-overlay-24);
}