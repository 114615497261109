.Button_view_filled-primary {
    height: var(--space-xl);
    color: var(--color-black-high);
}

.Button_view_filled-primary .Button-Content {
    padding: 0 var(--space-s);
}

.Button_view_filled-primary.Button_icon .Button-Content {
    padding: 0 var(--space-2-xs)
}

.Button_view_filled-primary .Button-Icon_side_left {
    margin-left: calc(-1 * var(--space-3-xs));
}

.Button_view_filled-primary .Button-Icon_side_right {
    margin-right: calc(-1 * var(--space-3-xs));
}

.Button_view_filled-primary::before {
    background: var(--color-primary-high);
}

.Button_view_filled-primary:hover::before {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-primary-high);
}

.Button_view_filled-primary:active::before,
.Button_view_filled-primary.Button_pressed::before {
    background: var(--color-primary-medium);
}

.Button_view_filled-primary:disabled::before {
    background: var(--color-primary-disabled);
}
