.Table-Row_marker_red.Table-Row .Table-BodyCell:first-child {
    position: relative;
    overflow: hidden;
}
.Table-Row_marker_red.Table-Row .Table-BodyCell:first-child::before {
    position: absolute;
    content: '';
    top: 12px;
    left: 0;
    height: calc(100% - 24px);
    width: 4px;
    background: #FA665B;
    border-radius: 0 var(--round-s) var(--round-s) 0;
}