.Chip.Chip_view_success {
    color: var(--color-success-high-emphasis);
    background: var(--color-success-12);
}

.Chip.Chip_view_success.Chip_disabled {
    color: var(--color-success-disabled);
}

.Chip.Chip_view_success.Chip_disabled:hover {
    cursor: default;
    color: var(--color-success-disabled);
    background: var(--color-success-12);
}

.Chip.Chip_view_success.Chip_hovered:hover {
    cursor: pointer;
    background: var(--color-success-12-8);
}