.Select {
    position: relative;
    display: inline-flex;
    border-radius: var(--round-m);
    scrollbar-width: none;
    color: var(--color-on-surface-high-emphasis);
    background: var(--color-white-hover);
}

.Select-InnerWrapper {
    position: relative;
    display: flex;
    width: inherit;
    overflow: hidden;
    border-radius: inherit;
}

.Select-InnerWrapper::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    content: '';
    transition: transform .2s cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid var(--color-primary-200-high-emphasis);
}

.Select_focused .Select-InnerWrapper::after {
    transform: scaleX(1);
}

.Select_error .Select-InnerWrapper::after {
    transform: scaleX(1);
    border-bottom: 2px solid var(--color-danger-high-emphasis);
}

.Select::-webkit-scrollbar {
    display: none;
}

.Select_readOnly,
.Select_readOnly .Select-Trigger {
    background: transparent;
    pointer-events: none;
}

.Select:hover {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-white-hover);
}

.Select-Icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: var(--space-3-xs);
}

.Select-Icon:hover {
    cursor: pointer;
}