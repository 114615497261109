.Button_view_text {
    height: 20px;
}

.Button_view_text:active::before,
.Button_view_text:active .Button-Content {
    transform: none;
}

.Button.Button_view_text:disabled {
    color: var(--color-white-disabled);
}

.Button_view_text,
.Button_view_text:hover:not(:disabled) {
    color: var(--color-primary-high);
}

.Button_view_text:active:not(:disabled),
.Button_view_text.Button_pressed:not(:disabled) {
    color: var(--color-primary-medium);
}

.Button_view_text.Button_color_red,
.Button_view_text.Button_color_red:hover:not(:disabled) {
    color: var(--color-status-red-high);
}

.Button_view_text.Button_color_red:active:not(:disabled),
.Button_view_text.Button_color_red.Button_pressed:not(:disabled) {
    color: var(--color-status-red-medium);
}