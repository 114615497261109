.Tabs_view_default {
    /* background: var(--color-on-surface-white-8); */
    position: relative;
    color: var(--color-on-surface-medium-emphasis);
    outline: none;
    white-space: nowrap;
    border-bottom: 1px solid var(--color-white-low);
}

.Tabs_view_default .Tabs-Tab {
    display: inline-flex;
    padding: 7px 0;
    margin-right: var(--space-m);
}

.Tabs_view_default .Tabs-Tab:last-of-type {
    margin-right: 0;
}

.Tabs_view_default .Tabs-Scroller {
    border-radius: var(--round-s) var(--round-s) 0 0;
    background: var(--color-primary-200-high-emphasis);
}

.Tabs_view_default .Tabs-Tab_active {
    color: var(--color-primary-200-high-emphasis);
}