.Checkbox-Tick {
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    visibility: hidden;


    pointer-events: none;
}

.Checkbox_checked .Checkbox-Tick {
    visibility: visible;
}

.Checkbox-Tick svg {
    width: 18px;
    height: 16px;
}

.Checkbox_disabled .Checkbox-Tick svg {
    fill: var(--color-primary-200-disabled);
}

.Checkbox_dark_theme .Checkbox-Tick {
    opacity: 0;
    transition: bottom .05s, opacity .05s, visibility 0s .05s;
    transition-timing-function: ease-out;
}

.Checkbox_dark_theme.Checkbox_checked .Checkbox-Tick {
    opacity: 1;

    transition-delay: 0s;
}