.Badge_type_counter {
    border-radius: var(--round-l);
}

.Badge.Badge_type_counter.Badge_view_default {
    color: var(--color-white-medium)
}

.Badge.Badge_type_counter.Badge_view_default.Badge_disabled {
    color: var(--color-white-disabled);
}