.Tabs_view_chips {
    background: var(--color-on-surface-white-8);
    position: relative;
    border-radius: var(--round-m);
    color: var(--color-on-surface-medium-emphasis);
    outline: none;
}

.Tabs_view_chips .Tabs-Tab {
    display: inline-flex;
    padding: 7px var(--space-xs);
    border-radius: var(--round-m);
}

.Tabs_view_chips .Tabs-Scroller {
    border-radius: var(--round-m);
    background: var(--color-primary-200-12);
}

.Tabs_view_chips .Tabs-Tab_active {
    color: var(--color-primary-200-high-emphasis);
}

