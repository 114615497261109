.Button_mode_tag {
    color: var(--color-white-high);
    padding: var(--space-3-xs) var(--space-xs);
    height: 24px;
}

.Button_mode_tag::before {
    background: var(--color-white-low);
}

.Button_mode_tag:active {
    color: var(--color-white-medium);
}

.Button_mode_tag:active::before {
    background: var(--color-white-low);
}

.Button_mode_tag:hover:not(:disabled)::before {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-white-low);
}

.Button_mode_tag:disabled {
    color: var(--color-white-disabled);
}