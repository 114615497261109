.Chip.Chip_view_primary {
    color: var(--color-primary-200-high-emphasis);
    background: var(--color-primary-200-12);
}

.Chip.Chip_view_primary.Chip_disabled {
    color: var(--color-primary-200-disabled);
}

.Chip.Chip_view_primary.Chip_disabled:hover {
    cursor: default;
    color: var(--color-primary-200-disabled);
    background: var(--color-primary-200-12);
}

.Chip.Chip_view_primary.Chip_hovered:hover {
    cursor: pointer;
    background: var(--color-primary-200-12-8);
}