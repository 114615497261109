.Menu-Footer {
    position: fixed;
    bottom: 0;
    background: inherit;
    width: 100%;
    margin-top: var(--space-2-xs);
    height: 32px;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.Menu-Button {
    padding: 0 var(--space-m);
    height: 100%;
    display: flex;
    align-items: center;
}

.Menu-Button:before {
    border-top: 1px solid var(--color-unsorted-outline);
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
}

.Menu-Button.Menu-Button_position_left {
    justify-content: flex-start;
}
.Menu-Button.Menu-Button_position_center {
    justify-content: center;
}

.Menu-Button.Menu-Button_position_right {
    justify-content: flex-end;
}

.Menu-Button .Text:hover {
    cursor: pointer;
}

.Menu-Button .Chip {
    margin-left: var(--space-3-xs);
}