.Badge.Badge_view_danger {
    color: var(--color-status-red-high);
    background: var(--color-status-red-low);
}

.Badge_view_danger.Badge_hovered:hover {
    cursor: pointer;
}

.Badge_view_danger.Badge_hovered:hover:before {
    background: var(--color-white-hover);
}

.Badge_view_danger.Badge_disabled {
    color: var(--color-status-red-disabled);
}

.Badge_view_danger.Badge_disabled:hover {
    cursor: default;
}

.Badge_view_danger.Badge_disabled:hover:before {
    background: none;
}