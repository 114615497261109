.Tabs-Tab {
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.Tabs-Tab_disabled {
    cursor: default;
}
