.Theme_color_dark {
    --color-unsorted-danger-scoring: #030919;
    --color-unsorted-statistic-track: #030919;
    --color-unsorted-background: #030919;
    --color-unsorted-outline: rgba(255, 255, 255, 0.12);
    --color-syntax-on-dark-key: #EEB696;
    --color-syntax-on-dark-values: #9BCBFA;
    --color-syntax-on-dark-operation: #ACC58E;
    --color-on-surface-high-emphasis: rgba(255, 255, 255, 0.87);
    --color-on-surface-medium-emphasis: rgba(255, 255, 255, 0.6);
    --color-on-surface-disabled: rgba(255, 255, 255, 0.38);
    --color-on-surface-white-12: rgba(255, 255, 255, 0.12);
    --color-on-surface-white-8: rgba(255, 255, 255, 0.08);
    --color-on-surface-hovered-state: rgba(255, 255, 255, 0.04);
    --color-primary-200-high-emphasis: #8DCAFE;
    --color-primary-200-medium-emphasis: rgba(141, 202, 254, 0.6);
    --color-primary-200-disabled: rgba(141, 202, 254, 0.38);
    --color-primary-200-12: rgba(141, 202, 254, 0.12);
    --color-primary-200-8: rgba(141, 202, 254, 0.08);
    --color-primary-200-4: rgba(141, 202, 254, 0.04);
    --color-on-primary-high-emphasis: #000000;
    --color-on-primary-medium-emphasis: rgba(0, 0, 0, 0.74);
    --color-on-primary-disabled: rgba(0, 0, 0, 0.38);
    --color-on-primary-12: rgba(0, 0, 0, 0.2);
    --color-on-primary-8: rgba(0, 0, 0, 0.12);
    --color-success-high-emphasis: #B0EE99;
    --color-success-medium-emphasis: rgba(176, 238, 153, 0.6);
    --color-success-disabled: rgba(176, 238, 153, 0.38);
    --color-success-12: rgba(176, 238, 153, 0.12);
    --color-success-8: rgba(176, 238, 153, 0.08);
    --color-success-4: rgba(187, 242, 162, 0.04);
    --color-warning-high-emphasis: #FFF5A4;
    --color-warning-medium-emphasis: rgba(255, 245, 164, 0.6);
    --color-warning-disabled: rgba(255, 245, 164, 0.38);
    --color-warning-12: rgba(255, 245, 164, 0.12);
    --color-warning-8: rgba(255, 245, 164, 0.08);
    --color-warning-4: rgba(255, 245, 164, 0.04);
    --color-danger-high-emphasis: #FC534E;
    --color-danger-medium-emphasis: rgba(252, 83, 78, 0.6);
    --color-danger-disabled: rgba(252, 83, 78, 0.38);
    --color-danger-12: rgba(252, 83, 78, 0.12);
    --color-danger-8: rgba(252, 83, 78, 0.08);
    --color-danger-4: rgba(252, 83, 78, 0.04);
    --color-on-danger-high-emphasis: #000000;
    --color-on-danger-medium-emphasis: rgba(0, 0, 0, 0.74);
    --color-on-danger-disabled: rgba(0, 0, 0, 0.6);
    --color-level-1: #030919;
    --color-primary-high: #8DCAFE;
    --color-primary-medium: rgba(141, 202, 254, 0.6);
    --color-primary-disabled: rgba(141, 202, 254, 0.4);
    --color-primary-low: rgba(141, 202, 254, 0.12);
    --color-white-high: rgba(255, 255, 255, 0.9);
    --color-white-medium: rgba(255, 255, 255, 0.6);
    --color-white-disabled: rgba(255, 255, 255, 0.4);
    --color-white-low: rgba(255, 255, 255, 0.12);
    --color-white-hover: rgba(255, 255, 255, 0.08);
    --color-black-high: rgba(0, 0, 0, 0.9);
    --color-black-medium: rgba(0, 0, 0, 0.6);
    --color-black-disabled: rgba(0, 0, 0, 0.4);
    --color-black-low: rgba(0, 0, 0, 0.12);
    --color-brand-high: #003CF5;
    --color-brand-medium: rgba(0, 60, 245, 0.6);
    --color-brand-disabled: rgba(0, 60, 245, 0.4);
    --color-brand-low: rgba(0, 60, 245, 0.12);
    --color-status-green-high: #02BB9F;
    --color-status-green-medium: rgba(2, 187, 159, 0.6);
    --color-status-green-disabled: rgba(2, 187, 159, 0.4);
    --color-status-green-low: rgba(2, 187, 159, 0.12);
    --color-status-yellow-high: #FFAF3C;
    --color-status-yellow-medium: rgba(255, 175, 60, 0.6);
    --color-status-yellow-disabled: rgba(255, 175, 60, 0.4);
    --color-status-yellow-low: rgba(255, 175, 60, 0.12);
    --color-status-red-high: #FA665B;
    --color-status-red-medium: rgba(250, 102, 91, 0.6);
    --color-status-red-disabled: rgba(250, 102, 91, 0.4);
    --color-status-red-low: rgba(250, 102, 91, 0.12);
    --color-status-blue-high: #0096FD;
    --color-status-blue-medium: rgba(0, 150, 253, 0.6);
    --color-status-blue-disabled: rgba(0, 150, 253, 0.4);
    --color-status-blue-low: rgba(0, 150, 253, 0.12);
}
