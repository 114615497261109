.TextField_mode_search .TextField-Control {
    caret-color: var(--color-on-surface-high-emphasis);
}

.TextField_mode_search .TextField-Container::after {
    display: none;
}

.TextField_focused.TextField_view_filled.TextField_mode_search .TextField-Label_floated {
    color: var(--color-on-surface-medium-emphasis);
}