.Select-Trigger {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--color-on-surface-high-emphasis);
    border-radius: inherit;
    cursor: text;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 1px var(--space-s);
    width: 100%;
}

.Select-Trigger::-webkit-scrollbar {
    display: none;
}

.Select-Container {
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    font-family: var(--typography-font-family-regular);
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 100%;
}

.Select-Container::-webkit-scrollbar {
    display: none;
}

.Select-MultiItem {
    padding: 1px;
}

.Select-Autosize {
    display: inline-block;
    padding: var(--space-2-xs) 0px;
}

.Select-Placeholder {
    position: relative;
    left: -3px;
    user-select: none;
}

.Select-Placeholder .Text {
    color: var(--color-on-surface-disabled);
}

.Select-Hidden {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: var(--typography-font-size-s-font-size);
    font-family: var(--typography-font-family-regular);
    visibility: hidden;
    height: 0px;
    overflow: scroll;
    white-space: pre;
    letter-spacing: normal;
    text-transform: none;
}

.Select-Input {
    background: 0 center;
    width: 2px;
    box-sizing: content-box;
    color: var(--color-on-surface-high-emphasis);
    outline: none;
    border: none;
    font-family: var(--typography-font-family-regular);
    font-size: var(--typography-font-size-s-font-size);
    white-space: normal;
    padding: 0;
}