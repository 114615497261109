.Button_view_text-red {
    height: 20px;
    color: var(--color-status-red-high);
}

.Button_view_text-red:active::before,
.Button_view_text-red:active .Button-Content {
    transform: none;
}

.Button_view_text-red:disabled {
    color: var(--color-white-disabled);
}

.Button_view_text-red:hover:not(:disabled) {
    color: var(--color-status-red-high);
}

.Button_view_text-red:active:not(:disabled),
.Button_view_text-red.Button_pressed:not(:disabled) {
    color: var(--color-status-red-medium);
}