.Badge {
    position: relative;
    overflow: hidden;
    padding: var(--space-4-xs) var(--space-2-xs);
    width: fit-content;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: var(--typography-font-weight-regular);
    font-family: var(--typography-font-family-regular);
    font-size: var(--typography-font-size-s-font-size);
    line-height: var(--typography-line-height-xs);
    transition: background-color, color 0.2s ease-out;
}

.Badge:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: background-color 0.2s ease-out;
}
