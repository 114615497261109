/* common */

.Popup_view_default {
    visibility: hidden;
    box-sizing: border-box;
    border-radius: var(--round-l);
    margin: -9999px 0 0 -9999px;    
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    animation-duration: .1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

.Popup_view_default::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    border-radius: inherit;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.Popup_view_default.Popup_visible::before {
    /* NOTE: ISL-5672 */
    z-index: -1;
}

.Popup_view_default.Popup_visible {
    visibility: visible;
    margin: 0;
}

.Popup.Popup_view_default .Popup-Tail::before {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 0;
    left: 0;
    content: '';
    border: 1px solid transparent;
    border-bottom-color: rgba(0, 0, 0, .06);
    border-left-color: rgba(0, 0, 0, .06);
}

.Popup_view_default .Popup-Tail::after {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 1px;
    left: 1px;
    content: '';
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(0deg, #030919, #030919);
}


/**
 * Необходимо, чтобы сохранить скругленные края попапа,
 * даже если у вложенного элемента скругления не будет.
 */


.Popup.Popup_view_default > :last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.Popup_view_default.Popup[data-popper-placement^='bottom'] {
    animation-name: popup_view_default_bottom;
}

.Popup_view_default.Popup[data-popper-placement^='bottom'].Popup_visible {
    animation-name: popup_view_default_bottom_visible;
}

.Popup_view_default.Popup[data-popper-placement^='top'] {
    animation-name: popup_view_default_top;
}

.Popup_view_default.Popup[data-popper-placement^='top'].Popup_visible {
    animation-name: popup_view_default_top_visible;
}

.Popup_view_default.Popup[data-popper-placement^='right'] {
    animation-name: popup_view_default_right;
}

.Popup_view_default.Popup[data-popper-placement^='right'].Popup_visible {
    animation-name: popup_view_default_right_visible;
}

.Popup_view_default.Popup[data-popper-placement^='left'] {
    animation-name: popup_view_default_left;
}

.Popup_view_default.Popup[data-popper-placement^='left'].Popup_visible {
    animation-name: popup_view_default_left_visible;
}

@keyframes popup_view_default_bottom {
    0% {
        visibility: visible;

        margin: 0;

        opacity: 1;

        transform: translateY(0);
    }

    99% {
        margin: 0;

        transform: translateY(10px);
    }

    100% {
        visibility: hidden;

        margin: -9999px 0 0 -9999px;

        opacity: 0;
    }
}

@keyframes popup_view_default_bottom_visible {
    0% {
        opacity: 0;

        transform: translateY(10px);
    }

    100% {
        opacity: 1;

        transform: translateY(0);
    }
}

@keyframes popup_view_default_top {
    0% {
        visibility: visible;

        margin: 0;

        opacity: 1;

        transform: translateY(0);
    }

    99% {
        margin: 0;

        transform: translateY(-10px);
    }

    100% {
        visibility: hidden;

        margin: -9999px 0 0 -9999px;

        opacity: 0;
    }
}

@keyframes popup_view_default_top_visible {
    0% {
        opacity: 0;

        transform: translateY(-10px);
    }

    100% {
        opacity: 1;

        transform: translateY(0);
    }
}

@keyframes popup_view_default_right {
    0% {
        visibility: visible;

        margin: 0;

        opacity: 1;

        transform: translateX(0);
    }

    99% {
        margin: 0;

        transform: translateX(10px);
    }

    100% {
        visibility: hidden;

        margin: -9999px 0 0 -9999px;

        opacity: 0;
    }
}

@keyframes popup_view_default_right_visible {
    0% {
        opacity: 0;

        transform: translateX(10px);
    }

    100% {
        opacity: 1;

        transform: translateX(0);
    }
}

@keyframes popup_view_default_left {
    0% {
        visibility: visible;

        margin: 0;

        opacity: 1;

        transform: translateX(0);
    }

    99% {
        margin: 0;

        transform: translateX(-10px);
    }

    100% {
        visibility: hidden;

        margin: -9999px 0 0 -9999px;

        opacity: 0;
    }
}

@keyframes popup_view_default_left_visible {
    0% {
        opacity: 0;

        transform: translateX(-10px);
    }

    100% {
        opacity: 1;

        transform: translateX(0);
    }
}