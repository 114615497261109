.Card_view_default {
    padding: var(--space-m);
    background: var(--color-elevation-overlay-02);
}

.Card_view_default.Card_hover {
    cursor: pointer;
    background: var(--color-on-surface-white-8);
}

.Card_view_default.Card_selected {
    background: var(--color-primary-200-8);
}