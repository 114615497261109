.Button_view_inline:active::before,
.Button_view_inline:active .Button-Content {
    transform: none;
}

.Button_view_inline,
.Button_view_inline:hover,
.Button_view_inline:active,
.Button_view_inline.Button_pressed {
    color: var(--color-white-high);
}

.Button_view_inline:disabled {
    color: var(--color-white-disabled);
}