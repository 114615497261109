.Button_mode_tag-select {
    color: var(--color-white-medium);
    padding: var(--space-xs) var(--space-s);
    height: 32px;
}

.Button_mode_tag-select::before {
    border: 1px solid var(--color-white-low);
}

.Button_mode_tag-select:hover::before {
    background: var(--color-white-hover);
}

/* pressed styles */
.Button_mode_tag-select.Button:active,
.Button_mode_tag-select.Button_pressed {
    color: var(--color-white-disabled);
}

.Button_mode_tag-select.Button:active:not(:disabled)::before,
.Button_mode_tag-select.Button_pressed:not(:disabled)::before {
    background: var(--color-white-hover);
}

/* disabled styles */
.Button_mode_tag-select:disabled,
.Button_mode_tag-select:disabled:hover {
    color: var(--color-white-disabled);
}

.Button_mode_tag-select:disabled::before {
    background: inherit;
}

/* active styles */
.Button_mode_tag-select.Button_active,
.Button_mode_tag-select.Button_active:hover,
.Button_mode_tag-select.Button_active:active,
.Button_mode_tag-select.Button_active.Button_pressed {
    color: var(--color-primary-high);
}

.Button_mode_tag-select.Button_active::before {
    border-color: var(--color-primary-low);
    background: var(--color-primary-low);
}

/* active hover styles */
.Button_mode_tag-select.Button_active:hover:not(:disabled)::before {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-primary-low);
}

/* active pressed styles */
.Button_mode_tag-select.Button_active:active:not(:disabled)::before,
.Button_mode_tag-select.Button_active.Button_pressed:not(:disabled)::before {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-primary-low);
}

/* active disabled styles */
.Button_mode_tag-select.Button_active:disabled {
    color: var(--color-primary-disabled);
}

