.Button {
    height: 32px;
    position: relative;
    display: inline-flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-user-focus: ignore;
    border: none;
    overflow: visible;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    outline: none;
    font-family: var(--typography-font-family-regular);
    font-size: var(--typography-font-size-xs-font-size);
    background: initial;
    border-radius: var(--round-m);
    transition: background, color 0.15s ease-out, transform 0.1s ease-out;
    touch-action: manipulation;
    z-index: 0;
}

.Button::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    border-radius: var(--round-m);
    transition: background 0.15s ease-out, transform 0.1s ease-out;
}

.Button-Content {
    display: inline-flex;
    align-items: center;
    transition: color 0.15s ease-out, transform 0.1s ease-out;
    height: 100%;
}

.Button:active::before,
.Button:active .Button-Content {
    transform: scale(.96);
}

.Button:disabled {
    background: inherit;
    cursor: default;
    color: var(--color-white-disabled);
}

.Button:disabled:active::before,
.Button:disabled:active .Button-Content {
    transform: none;
}