.Button_view_link {
    height: var(--space-l);
    color: var(--color-primary-high);
}

.Button_view_link .Button-Content {
    padding: 0 var(--space-xs);
}

.Button_view_link.Button_icon .Button-Content {
    padding: 0 var(--space-2-xs);
}

.Button_view_link::before,
.Button_view_link:active::before,
.Button_view_link.Button_pressed::before,
.Button_view_link:disabled::before {
    background: var(--color-primary-low);
}

.Button_view_link:hover:not(:disabled):not(:active):not(.Button_pressed)::before {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-primary-low);
}

.Button_view_link:active,
.Button_view_link.Button_pressed,
.Button_view_link:disabled {
    color: var(--color-primary-medium);
}
