.Table_view_card {
    color: var(--color-on-surface-high-emphasis);
    border-spacing: 0 var(--space-3-xs);
}

.Table_view_card .Table-HeadCell {
    padding: var(--space-s) var(--space-m);
    color: var(--color-on-surface-disabled);
}

.Table_view_card .Table-Row {
    height: 48px;
}

.Table_view_card .Table-Body .Table-Row_hover .Table-BodyCell {
    cursor: pointer;
    background: var(--color-elevation-overlay-08);
}

.Table_view_card .Table-BodyCell {
    padding: var(--space-s) var(--space-m);
    background: var(--color-elevation-overlay-02);
}

.Table_view_card .Table-Row .Table-BodyCell:first-child {
    border-radius: var(--round-l) 0 0 var(--round-l);
}

.Table_view_card .Table-Row .Table-BodyCell:last-child {
    border-radius: 0 var(--round-l) var(--round-l) 0;
}