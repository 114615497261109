.Button_view_filled-brand {
    height: var(--space-xl);
    color: var(--color-white-high);
}

.Button_view_filled-brand .Button-Content {
    padding: 0 var(--space-s);
}

.Button_view_filled-brand.Button_icon .Button-Content {
    padding: 0 var(--space-2-xs)
}

.Button_view_filled-brand .Button-Icon_side_left {
    margin-left: calc(-1 * var(--space-3-xs));
}

.Button_view_filled-brand .Button-Icon_side_right {
    margin-right: calc(-1 * var(--space-3-xs));
}

.Button_view_filled-brand::before {
    background: var(--color-brand-high);
}

.Button_view_filled-brand:hover::before {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-brand-high);
}

.Button_view_filled-brand:active::before,
.Button_view_filled-brand.Button_pressed::before {
    background: var(--color-brand-medium);
}

.Button_view_filled-brand:disabled::before {
    background: var(--color-brand-low);
}
