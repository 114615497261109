.Chip.Chip_view_warning {
    color: var(--color-warning-high-emphasis);
    background: var(--color-warning-12);
}

.Chip.Chip_view_warning.Chip_disabled {
    color: var(--color-warning-disabled);
}

.Chip.Chip_view_warning.Chip_disabled:hover {
    cursor: default;
    color: var(--color-warning-disabled);
    background: var(--color-warning-12);
}

.Chip.Chip_view_warning.Chip_hovered:hover {
    cursor: pointer;
    background: var(--color-warning-12-8);
}