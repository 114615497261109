.TextArea {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    font-family: var(--typography-font-family-regular);
    font-size: var(--typography-font-size-m-font-size);
}

.TextArea-Box {
    position: relative;
}

.TextArea-Container::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    content: '';
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid var(--color-primary-200-high-emphasis);
}

.TextArea_focused .TextArea-Container::after {
    transform: scaleX(1);
}

.TextArea_error .TextArea-Container::after {
    transform: scaleX(1);
    border-bottom: 2px solid var(--color-danger-high-emphasis);
}

.TextArea-Container {
    display: flex;
    align-items: center;
    position: relative;
    width: 200px;
    height: 100px;
    background: var(--color-white-hover);
    border-radius: var(--round-m);
    overflow: hidden;
    cursor: text;
}

.TextArea-Container:hover {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-white-hover);
}

.TextArea_readOnly .TextArea-Container,
.TextArea_disabled .TextArea-Container {
    cursor: default;
}

.TextArea-BottomPanel {
    color: var(--color-on-surface-medium-emphasis);
    margin: var(--space-3-xs) 0px 0px var(--space-s);
    display: flex;
    justify-content: space-between;
    line-height: var(--typography-line-height-xs);
    cursor: default;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.TextArea-HelperText {
    color: inherit;
    line-height: inherit;
}

.TextArea-Counter {
    color: inherit;
    line-height: inherit;
}

.TextArea_error .TextArea-HelperText {
    color: var(--color-danger-high-emphasis);
}

.TextArea-ControlBox {
    position: relative;
    flex: auto;
    height: 100%;
    box-sizing: border-box;
    padding: 25px 12px 11px;
    /* margin: 25px 12px 11px; */
}