
.TextField.TextField_view_filled .TextField-Control {
    padding: 24px 12px 10px;
}

.TextField_error .TextField-Label {
    color: var(--color-danger-high-emphasis);
}

/* .TextField_disabled.TextField_view_filled .TextField-Label {
    transform: none;
} */

.TextField_view_filled .TextField-Label_floated {
    display: block;
    top: 28px;
    transform: translate(-3px, -20px) scale(0.85);
}

.TextField_focused.TextField_view_filled .TextField-Label {
    display: block;
    top: 28px;
    transform: translate(-3px, -20px) scale(0.85); 
    color: var(--color-primary-200-high-emphasis);
}

.TextField_view_filled.TextField_error .TextField-Label_floated {
    color: var(--color-danger-high-emphasis);
}

.TextField_view_filled.TextField_error .TextField-Control {
    color: var(--color-danger-high-emphasis);
}

.TextField_filled.TextField_readOnly:not(.TextField_disabled) .TextField-Label_floated {
    display: block;
    top: 28px;
    transform: translate(-3px, -20px) scale(0.85); 
    color: var(--color-on-surface-medium-emphasis);
}
