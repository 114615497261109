
.TextField_view_transparent .TextField-Container {
    background: transparent;
}

.TextField_focused.TextField_view_transparent .TextField-Container{
    transition: background 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    background: var(--color-on-surface-hovered-state);
}

.TextField_disabled.TextField_view_transparent .TextField-Container:hover,
.TextField_readOnly.TextField_view_transparent .TextField-Container:hover {
    background: transparent;
}