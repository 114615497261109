.Checkbox {
    display: inline-flex;
    align-items: center;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    white-space: nowrap;
    outline: none;
    font-family: var(--typography-font-family-regular);
    font-size: var(--typography-font-size-m-font-size);
    touch-action: manipulation;
}