.Button-Badge {
    margin-left: var(--space-2-xs);
    margin-right: var(--space-2-xs);
}

.Button_mode_tag-select-count {
    padding: var(--space-2-xs) var(--space-s);
}

.Button_mode_tag-select-count.Button_active:disabled .Button-Badge {
    color: var(--color-primary-medium);
}

.Button_mode_tag-select-count.Button_dropdown,
.Button_mode_tag-select-count.Button_dropdown:hover {
    color: var(--color-primary-high);
}

.Button.Button_mode_tag-select-count.Button_dropdown::before,
.Button.Button_mode_tag-select-count.Button_dropdown:hover::before {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-primary-low);
}