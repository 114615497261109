.Tabs_view_list .Tabs-Tab {
    position: relative;

    color: var(--color-on-surface-medium-emphasis);
    outline: none;
    padding: var(--space-xs) var(--space-m);
    border-radius: var(--round-m);

    margin-bottom: var(--space-xs);
}

.Tabs_view_list .Tabs-Scroller {
    border-radius: var(--round-m);
    background: var(--color-primary-200-12);
}

.Tabs_view_list .Tabs-Tab_active {
    color: var(--color-primary-200-high-emphasis);
}
