.Chip.Chip_view_danger {
    color: var(--color-danger-high-emphasis);
    background: var(--color-danger-12);
}

.Chip.Chip_view_danger.Chip_disabled {
    color: var(--color-danger-disabled);
}

.Chip.Chip_view_danger.Chip_disabled:hover {
    cursor: default;
    color: var(--color-danger-disabled);
    background: var(--color-danger-12);
}

.Chip.Chip_view_danger.Chip_hovered:hover {
    cursor: pointer;
    background: var(--color-danger-12-8);
}