
.Checkbox.Checkbox_size_m .Checkbox-Box {
    width: 18px;
    height: 18px;
    min-width: 18px;
}

.Checkbox_size_m .Checkbox-Tick svg {
    width: 20px;
    height: 18px;
}

.Checkbox_size_m .Checkbox-Label {
    margin-left: 12px;
    font-size: var(--typography-font-size-m-font-size);
}
