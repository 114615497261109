.TextField {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: var(--typography-font-family-regular);
    font-size: var(--typography-font-size-m-font-size);
}

.TextField-Container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    overflow: hidden;
    color: var(--color-on-surface-high-emphasis);
    background: var(--color-white-hover);
    border-radius: var(--round-m);
    cursor: text;
}

.TextField-Container:hover {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-white-hover);
}

.TextField_focused .TextField-Container {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-white-hover);
}

.TextField_disabled .TextField-Container,
.TextField_readOnly .TextField-Container {
    cursor: default;
}

.TextField_disabled .TextField-Container:hover,
.TextField_readOnly .TextField-Container:hover {
    background: var(--color-on-surface-hovered-state);
}

.TextField-Container::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    content: '';
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid var(--color-primary-200-high-emphasis);
}

.TextField_focused .TextField-Container::after {
    transform: scaleX(1);
}

.TextField_error .TextField-Container::after {
    transform: scaleX(1);
    border-bottom: 2px solid var(--color-danger-high-emphasis);
}

.TextField-HelperText {
    color: var(--color-on-surface-medium-emphasis);
    margin: var(--space-3-xs) var(--space-s) 0px;
    line-height: var(--typography-line-height-xs);
    cursor: default;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.TextField_error .TextField-HelperText {
    color: var(--color-danger-high-emphasis);
}

.TextField-ControlBox {
    position: relative;
    flex: auto;
}