.Icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    height: min-content;
}

.Icon svg,
.Icon svg * {
    fill: currentColor;
}