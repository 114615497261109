.TextArea-Label {
    position: absolute;
    z-index: 3;
    top: 17px;
    left: 11px;
    transition: transform .15s ease-out;
    transform-origin: left top;
    pointer-events: none;
    font-size: var(--typography-font-size-s-font-size);
    color: var(--color-on-surface-medium-emphasis);
}


.TextArea-Label_floated {
    transform: translateY(-8px) scale(0.85);
    transform-origin: left top;
}

.TextArea_focused .TextArea-Label_floated {
    color: var(--color-primary-200-high-emphasis);
}

.TextArea_error .TextArea-Label {
    color: var(--color-danger-high-emphasis);
}

.TextArea_readOnly .TextArea-Label,
.TextArea_disabled .TextArea-Label {
    color: var(--color-on-surface-disabled);
}
