.TextField-Label {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%) scale(1);
    transition: transform .15s ease-out;
    left: 12px;
    pointer-events: none;
    color: var(--color-on-surface-medium-emphasis);
    font-size: var(--typography-font-size-s-font-size);
}

.TextField_error .TextField-Label {
    color: var(--color-danger-high-emphasis);
}

.TextField_disabled .TextField-Label {
    color: var(--color-on-surface-disabled);
}

.TextField-Label_floated {
    display: none;
}
