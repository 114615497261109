.Select-Value {
    overflow-wrap: anywhere;
    margin: var(--space-4-xs) 3px var(--space-4-xs) 0;
}


.Select-Value .Text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.Select-Value.Chip .Chip-Icon:hover {
    cursor: pointer;
}
