.Menu_view_default {
    color: var(--color-on-surface-high-emphasis);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(0deg, #030919, #030919);
    box-shadow: var(--color-elevation-overlay-04);

    scrollbar-width: none;
}

.Menu_view_default .Menu-Item {
    position: relative;
    cursor: pointer;
}
.Menu_view_default .Menu-Item .Text {
    white-space: break-spaces;
    overflow-wrap: anywhere;
}

.Menu_view_default .Menu-Group:first-child {
    padding-top: 0;
    border-top: none;
}

.Menu_view_default .Menu-Group:last-child {
    padding-bottom: 0;
}

.Menu_view_default::-webkit-scrollbar {
    display: none;
}

.Menu_view_default .Menu-Title::after,
.Menu_view_default .Menu-Title::before {
    width: 100%;
    height: 1px;
    content: '';
    background-color: var(--color-unsorted-outline);
}
.Menu_view_default .Menu-Title::after {
    margin-left: var(--space-xs);
}
.Menu_view_default .Menu-Title::before {
    margin-right: var(--space-xs);
}
.Menu_view_default .Menu-Title {
    display: flex;
    padding: var(--space-xs) var(--space-s);
    justify-content: space-between;
    align-items: center;
}
.Menu_view_default .Menu-Title .Text {
    white-space: nowrap;
}


/* stylelint-disable-next-line selector-max-universal */

.Menu_view_default .Menu-Item * {
    /* Обнуляем значения для интерактивных элементов внутри пункта меню. */
    text-decoration: none;
    outline: none;
}


/* disable */

.Menu_view_default.Menu_disabled .Menu-Item,
.Menu_view_default .Menu-Item.Menu-Item_disabled {
    opacity: .3;
    color: var(--color-on-surface-disabled);
}

.Menu_view_default .Menu-Item.Menu-Item_hovered {
    color: var(--color-on-surface-high-emphasis);
    background: var(--color-on-surface-hovered);
}