.Select.Select_view_filled .Select-Trigger {
    padding: 24px 12px 4px 12px;
}

.Select_error .Select-Label {
    color: var(--color-danger-high-emphasis);
}

.Select_view_filled .Select-Label_floated {
    display: block;
    top: 30px;
    transform: translate(-3px, -20px) scale(0.85);
}

.Select_focused.Select_view_filled .Select-Label {
    display: block;
    top: 30px;
    transform: translate(-3px, -20px) scale(0.85);
    color: var(--color-primary-200-high-emphasis);
}

.Select_error.Select_view_filled .Select-Label_floated {
    color: var(--color-danger-high-emphasis);
}

.Select_filled.Select_readOnly:not(.Select_disabled) .Select-Label_floated {
    display: block;
    top: 30px;
    transform: translate(-3px, -20px) scale(0.85);
    color: var(--color-on-surface-medium-emphasis);
}