.Button_view_ghost {
    height: var(--space-xl);
    color: var(--color-primary-high);
}

.Button_view_ghost .Button-Content {
    padding: 0 var(--space-s);
}

.Button_view_ghost.Button_icon .Button-Content {
    padding: 0 var(--space-2-xs);
}

.Button_view_ghost .Button-Icon_side_left {
    margin-left: calc(-1 * var(--space-3-xs));
}

.Button_view_ghost .Button-Icon_side_right {
    margin-right: calc(-1 * var(--space-3-xs));
}

.Button_view_ghost:hover::before,
.Button_view_ghost:active::before,
.Button_view_ghost.Button_pressed::before {
    background: var(--color-white-hover);
}

.Button_view_ghost:active,
.Button_view_ghost.Button_pressed {
    color: var(--color-primary-medium);
}

.Button_view_ghost:disabled {
    color: var(--color-primary-disabled);
}

.Button_view_ghost:disabled::before {
    background: transparent;
}