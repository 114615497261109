.Badge.Badge_view_success {
    color: var(--color-status-green-high);
    background: var(--color-status-green-low);
}

.Badge_view_success.Badge_hovered:hover {
    cursor: pointer;
}

.Badge_view_success.Badge_hovered:hover:before {
    background: var(--color-white-hover);
}

.Badge_view_success.Badge_disabled {
    color: var(--color-status-green-disabled);
}

.Badge_view_success.Badge_disabled:hover {
    cursor: default;
}

.Badge_view_success.Badge_disabled:hover:before {
    background: none;
}