.Chip.Chip_view_default {
    color: var(--color-on-surface-high-emphasis);
    background: var(--color-on-surface-white-12);
}

.Chip.Chip_view_default.Chip_disabled {
    color: var(--color-on-surface-disabled);
}

.Chip.Chip_view_default.Chip_disabled:hover {
    cursor: default;
    color: var(--color-on-surface-disabled);
    background: var(--color-on-surface-white-12);
}

.Chip.Chip_view_default.Chip_hovered:hover {
    cursor: pointer;
    background: var(--color-on-surface-hovered);
}