.Button_view_outline {
    height: var(--space-xl);
    color: var(--color-primary-high);
}

.Button_view_outline .Button-Content {
    padding: 0 var(--space-s);
}

.Button_view_outline.Button_icon .Button-Content {
    padding: 0 var(--space-2-xs);
}

.Button_view_outline .Button-Icon_side_left {
    margin-left: calc(-1 * var(--space-3-xs));
}

.Button_view_outline .Button-Icon_side_right {
    margin-right: calc(-1 * var(--space-3-xs));
}

.Button_view_outline::before {
    border: 1px solid var(--color-primary-low);
}

.Button_view_outline:active,
.Button_view_outline.Button_pressed {
    color: var(--color-primary-medium);
}

.Button_view_outline:disabled {
    color: var(--color-primary-disabled);
}

.Button_view_outline:hover:not(:disabled)::before,
.Button_view_outline:active:not(:disabled)::before,
.Button_view_outline.Button_pressed:not(:disabled)::before {
    background: var(--color-white-hover);
}
