.Theme_root_dark {
    --color-unsorted-surface: var(--color-unsorted-background);
    --color-on-surface-hovered: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), rgba(255, 255, 255, 0.12);
    --color-primary-200-hovered-state: linear-gradient(0deg, rgba(255, 255, 255, 0.38), rgba(255, 255, 255, 0.38)), #8DCAFE;
    --color-primary-200-12-8: linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)), rgba(255, 255, 255, 0.12);
    --color-success-12-8: linear-gradient(0deg, rgba(176, 238, 153, 0.08), rgba(176, 238, 153, 0.08)), rgba(176, 238, 153, 0.12);
    --color-warning-12-8: linear-gradient(0deg, rgba(255, 245, 164, 0.08), rgba(255, 245, 164, 0.08)), rgba(255, 245, 164, 0.12);
    --color-danger-12-8: linear-gradient(0deg, rgba(252, 83, 78, 0.08), rgba(252, 83, 78, 0.08)), rgba(252, 83, 78, 0.12);
    --color-elevation-overlay-12: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #030919;
    --color-elevation-overlay-16: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #030919;
    --color-elevation-overlay-24: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #030919;
    --color-elevation-overlay-01: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #030919;
    --color-elevation-overlay-02: linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), #030919;
    --color-elevation-overlay-03: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #030919;
    --color-elevation-overlay-04: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), #030919;
    --color-elevation-overlay-06: linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), #030919;
    --color-elevation-overlay-08: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #030919;
    --color-level-2: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.04) 100%), #030919;
    --color-level-3: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #030919;
    --color-level-4: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #030919;
    --color-level-5: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #030919;
    --color-level-6: linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24)), #030919;
    --round-s: 4px;
    --round-m: 8px;
    --round-l: 16px;
    --space-4-xs: 2px;
    --space-3-xs: 4px;
    --space-2-xs: 6px;
    --space-xs: 8px;
    --space-s: 12px;
    --space-m: 16px;
    --space-l: 24px;
    --space-xl: 32px;
    --space-2-xl: 40px;
    --space-3-xl: 48px;
    --typography-line-height-xs: 16px;
    --typography-line-height-s: 24px;
    --typography-line-height-m: 32px;
    --typography-line-height-l: 40px;
    --typography-line-height-xl: 46px;
    --typography-line-height-2-xl: 56px;
    --typography-font-family-regular: 'Roboto', Helvetica, Arial, sans-serif;
    --typography-font-family-roboto: 'Roboto';
    --typography-font-family-roboto-mono: 'Roboto Mono';
    --typography-font-weight-light: 300;
    --typography-font-weight-regular: 400;
    --typography-font-weight-medium: 500;
    --typography-font-weight-bold: 700;
    --typography-font-size-2-xs-font-size: 11px;
    --typography-font-size-xs-font-size: 12px;
    --typography-font-size-s-font-size: 13px;
    --typography-font-size-m-font-size: 16px;
    --typography-font-size-l-font-size: 22px;
    --typography-font-size-xl-font-size: 32px;
    --typography-font-size-2-xl-font-size: 42px;
    --typography-font-size-3-xl-font-size: 56px;
}
