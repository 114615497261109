.Popup-Tail {
    position: absolute;
    z-index: -1;
    width: 10px;
    height: 10px;
}

.Popup[data-popper-placement^='top'] .Popup-Tail {
    transform: rotate(-45deg);
    bottom: -4px;
}

.Popup[data-popper-placement^='bottom'] .Popup-Tail {
    transform: rotate(135deg);
    top: -4px;
}

.Popup[data-popper-placement^='left'] .Popup-Tail {
    transform: rotate(-135deg);
    right: -4px;
}

.Popup[data-popper-placement^='right'] .Popup-Tail {
    transform: rotate(45deg);
    left: -4px;
}