.TextArea-Control {
    /* font-family: var(--typography-font-family-roboto); */
    position: relative;
    resize: none;
    border: none;
    outline: none;
    background: transparent;
    color: var(--color-on-surface-high-emphasis);
    margin: 0;
    padding: 0;
    scrollbar-width: none;
    line-height: 16px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    min-width: 100px;
}

.TextArea-Control::-webkit-scrollbar {
    display: none;
}

.TextArea-Control::placeholder {
    opacity: 0;
}

.TextArea_focused .TextArea-Control::placeholder {
    opacity: 1;
    color: var(--color-on-surface-disabled);
}
