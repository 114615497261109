.Checkbox-Box {
    position: relative;
    z-index: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    width: 18px;
    height: 18px;
    min-width: 18px;
    line-height: normal;
}

.Checkbox_checked .Checkbox-Box {
    color: var(--color-on-primary-high-emphasis);
}

.Checkbox-Box::after {
    position: absolute;
    background-color: transparent;
    content: '';
    transition: background-color .15s ease-in;
}

.Checkbox-Box::before {
    transition: none;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 0 0 2px var(--color-on-surface-medium-emphasis);
    border-radius: var(--round-s);
    box-sizing: border-box;
    content: '';
}

.Checkbox_disabled .Checkbox-Box::before {
    box-shadow: inset 0 0 0 2px var(--color-on-surface-disabled);
}

.Checkbox-Box:hover::after {
    position: absolute;
    border-radius: 50%;
    background-color: var(--color-on-surface-white-8);
    z-index: -10;
    width: 36px;
    height: 36px;
    content: '';
}

.Checkbox-Box:active::after {
    background-color: var(--color-on-surface-white-12);
}

.Checkbox_disabled .Checkbox-Box:hover::after {
    background-color: transparent;
}

.Checkbox_checked .Checkbox-Box::before {
    background: var(--color-primary-200-high-emphasis);
    box-shadow: none;
}

.Checkbox_disabled.Checkbox_checked .Checkbox-Box::before {
    background: var(--color-primary-200-disabled);
}