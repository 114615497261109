.TextField-Control {
    padding: 8px 12px;
    border: none;
    background: transparent;
    color: inherit;
    transition: background 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 16px;
    caret-color: var(--color-primary-200-high-emphasis);
    width: 100%;
    min-width: 100px;
    box-sizing: border-box;
    outline: none;
}

.TextField_readOnly .TextField-Control {
    color: var(--color-on-surface-disabled);
}

.TextField_error .TextField-Control {
    caret-color: var(--color-on-surface-high-emphasis);
}

.TextField-Control::placeholder {
    font-size: var(--typography-font-size-s-font-size);
    color: var(--color-on-surface-disabled);
    opacity: 0;
}

.TextField_focused .TextField-Control::placeholder,
.TextField:not(.TextField_label) .TextField-Control::placeholder {
    opacity: 1;
}
