.Menu {
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    font-family: var(--typography-font-family-regular);
    font-size: var(--typography-font-size-s-font-size);
    user-select: none;
    outline: 0;
    min-width: 112px;
    max-height: 336px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    scrollbar-width: none;
    padding: 16px 0;
}


.Menu_disabled,
.Menu-Item_disabled,
.Menu-Group_disable {
    cursor: default;
    pointer-events: none;
}


.Menu.Menu_withButton {
    padding: var(--space-m) 0 var(--space-xl) 0;
}
/**
 * FF игнорирует padding-bottom у контейнеров с overfolow:auto,
 * поэтому поля у меню реализуются с помощью псевдо-элементов.
 * Подробнее: https://bugzilla.mozilla.org/show_bug.cgi?id=748518
 */

.Menu::before,
.Menu::after {
    display: block;
    content: '';
}