.Button_view_link-red {
    height: var(--space-l);
    color: var(--color-status-red-high);
}

.Button_view_link-red .Button-Content {
    padding: 0 var(--space-xs);
}

.Button_view_link-red.Button_icon .Button-Content {
    padding: 0 var(--space-2-xs);
}

.Button_view_link-red::before,
.Button_view_link-red:active::before,
.Button_view_link-red.Button_pressed::before,
.Button_view_link-red:disabled::before {
    background: var(--color-status-red-low);
}

.Button_view_link-red:hover:not(:disabled):not(:active):not(.Button_pressed)::before {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-status-red-low);
}

.Button_view_link-red:active,
.Button_view_link-red.Button_pressed,
.Button_view_link-red:disabled {
    color: var(--color-status-red-medium)
}
