.Badge_type_dot {
    width: 0;
    height: 0;
    padding: var(--space-2-xs);
    border-radius: 50%;
}

.Badge_type_dot:before {
    display: none;
}

.Badge.Badge_type_dot.Badge_view_default {
    background: var(--color-white-medium);
}

.Badge_type_dot.Badge_view_default.Badge_hovered:hover {
    background: var(--color-white-high);
}

.Badge_type_dot.Badge_view_default.Badge_disabled {
    background: var(--color-white-disabled);
}

.Badge.Badge_type_dot.Badge_view_success {
    background: var(--color-status-green-high);
}

.Badge_type_dot.Badge_view_success.Badge_disabled {
    background: var(--color-status-green-disabled);
}

.Badge.Badge_type_dot.Badge_view_warning {
    background: var(--color-status-yellow-high);
}

.Badge_type_dot.Badge_view_warning.Badge_disabled {
    background: var(--color-status-yellow-disabled);
}

.Badge.Badge_type_dot.Badge_view_danger {
    background: var(--color-status-red-high);
}

.Badge_type_dot.Badge_view_danger.Badge_disabled {
    background: var(--color-status-red-disabled);
}

.Badge.Badge_type_dot.Badge_view_primary {
    background: var(--color-primary-high);
}

.Badge_type_dot.Badge_view_primary.Badge_disabled {
    background: var(--color-primary-disabled);
}
