.Menu-Item {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: flex-start;
    white-space: nowrap;
    padding: 0 var(--space-m);
    min-height: 32px;
    font-size: var(--typography-font-size-s-font-size);
}

.Menu-Item .Text {
    white-space: normal;
}