.Icon_type_arrow {
    height: 24px;
    width: 24px;
    background-image: url('./icon_type_arrow.assets/arrow.svg');
    transition: transform 0.15s linear;
}

.Icon_type_arrow.Icon_size_s {
    height: 16px;
    width: 16px;
    background-image: url('./icon_type_arrow.assets/arrow_s.svg');
}
.Icon_type_arrow.Icon_size_m {
    height: 20px;
    width: 20px;
    background-image: url('./icon_type_arrow.assets/arrow_m.svg');
}

.Icon_type_arrow.Icon_direction_left {
    transform: rotate(180deg);
}

.Icon_type_arrow.Icon_direction_bottom {
    transform: rotate(90deg);
}
.Icon_type_arrow.Icon_direction_top {
    transform: rotate(-90deg);
}