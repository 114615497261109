.Button_view_outline-red {
    height: var(--space-xl);
    color: var(--color-status-red-high);
}

.Button_view_outline-red .Button-Content {
    padding: 0 var(--space-s);
}

.Button_view_outline-red.Button_icon .Button-Content {
    padding: 0 var(--space-2-xs);
}

.Button_view_outline-red .Button-Icon_side_left {
    margin-left: calc(-1 * var(--space-3-xs));
}

.Button_view_outline-red .Button-Icon_side_right {
    margin-right: calc(-1 * var(--space-3-xs));
}

.Button_view_outline-red::before {
    border: 1px solid var(--color-status-red-low);
}

.Button_view_outline-red:active,
.Button_view_outline-red.Button_pressed {
    color: var(--color-status-red-medium);
}

.Button_view_outline-red:disabled {
    color: var(--color-status-red-disabled);
}

.Button_view_outline-red:hover:not(:disabled)::before,
.Button_view_outline-red:active:not(:disabled)::before,
.Button_view_outline-red.Button_pressed:not(:disabled)::before {
    background: var(--color-white-hover);
}

