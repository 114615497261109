.Button_mode_tag-close {
    color: var(--color-white-high);
    padding: var(--space-3-xs) var(--space-xs);
    height: 24px;
}

.Button_mode_tag-close .Button-Close  {
    height: 20px;
    display: flex;
    align-items: center;
    margin-left: 2px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 50%;
    transition: background 0.15s ease-out, color  0.15s ease-out, transform 0.1s ease-out;
}

.Button_mode_tag-close .Button-Close.Button-Close_pressed {
    transform: scale(.9);
    color: var(--color-white-medium);
}

.Button_mode_tag-close .Button-Close .Button-Icon_side_right {
    margin-left: 0;
}

.Button_mode_tag-close .Button-Close.Button-Close_hovered {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-white-low);
}

.Button_mode_tag-close::before {
    background: var(--color-white-low);
}

.Button_mode_tag-close:active {
    color: var(--color-white-medium);
}

.Button_mode_tag-close:active::before {
    background: var(--color-white-low);
}

.Button_mode_tag-close.Button_hovered:not(:disabled)::before {
    background: linear-gradient(0deg, var(--color-white-hover), var(--color-white-hover)), var(--color-white-low);
}

.Button_mode_tag-close:disabled {
    color: var(--color-white-disabled);
}