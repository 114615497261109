.Table-BodyCell, .Table-HeadCell {
    display: table-cell;
    word-break: break-word;
    text-align: left;
    padding: 20px 16px;
}

.Table-BodyCell_textAlign_center, .Table-HeadCell_textAlign_center {
    text-align: center;
}

.Table-BodyCell_textAlign_inherit,  .Table-HeadCell_textAlign_inherit {
    text-align: inherit;
}

.Table-BodyCell_textAlign_justify, .Table-HeadCell_textAlign_justify {
    text-align: justify;
}

.Table-BodyCell_textAlign_left, .Table-HeadCell_textAlign_left {
    text-align: left;
}

.Table-BodyCell_textAlign_right,  .Table-HeadCell_textAlign_right {
    text-align: right;
}
