
.Switch-Label {
    margin-left: 0;
    margin-right: 8px;
    cursor: pointer;
    color: var(--color-on-surface-high-emphasis);
    font-size: var(--typography-font-size-s-font-size);
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.Switch_disabled .Switch-Label {
    cursor: default;
    color: var(--color-on-surface-disabled);
}