.Badge.Badge_view_primary {
    color: var(--color-primary-high);
    background: var(--color-primary-low);
}

.Badge_view_primary.Badge_hovered:hover {
    cursor: pointer;
}

.Badge_view_primary.Badge_hovered:hover:before {
    background: var(--color-white-hover);
}

.Badge_view_primary.Badge_disabled {
    color: var(--color-primary-disabled);
}

.Badge_view_primary.Badge_disabled:hover {
    cursor: default;
}

.Badge_view_primary.Badge_disabled:hover:before {
    background: none;
}
