.Switch {
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: var(--typography-font-family-regular);
    font-size: var(--typography-font-size-m-font-size);
}

.Switch-Control {
    cursor: pointer;
    position: absolute;
    background: transparent;
    opacity: 0;
    z-index: 1;
    /* width: 36px; */
    width: 46px;
    height: 40px;
    top: -10px;
    left: -10px;
    margin: 0;
}

.Switch_disabled .Switch-Control {
    cursor: default;
}

.Switch_checked .Switch-Control {
    transform: translateX(-6px);
}

.Switch-Toggle {
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
    width: 58px;
    height: 40px;
    padding: 12px;
}

.Switch-Thumb {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #9FA1A7;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.Switch-Track {
    width: 100%;
    height: 100%;
    border-radius: var(--round-m);
    background-color: var(--color-on-surface-disabled);
}

.Switch_disabled .Switch-Toggle {
    cursor: default;
}

.Switch-Thumb::after {
    cursor: pointer;
    position: absolute;
    background-color: transparent;
    content: '';
    transition: background-color .15s ease-in;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    top: -10px;
    left: -10px
}

.Switch_disabled .Switch-Thumb::after {
    background-color: transparent;
}

.Switch-Thumb:hover::after {
    background-color: var(--color-on-surface-hovered-state);
}

.Switch-Thumb:active::after {
    background-color: var(--color-on-surface-white-12);
}

.Switch_checked .Switch-Thumb:hover::after {
    background-color: rgba(141, 202, 254, 0.04);
}

.Switch_checked .Switch-Thumb:active::after {
    background-color: rgba(141, 202, 254, 0.12)
}

.Switch_checked .Switch-Thumb {
    background-color: var(--color-primary-200-high-emphasis);
    transform: translateX(18px);
}

.Switch_checked .Switch-Track {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: var(--color-primary-200-disabled);
}

.Switch_disabled .Switch-Track {
    background: var(--color-primary-200-disabled);
    opacity: 0.38;
}

.Switch_disabled .Switch-Thumb {
    background: var(--color-primary-200-disabled);
}

.Switch_checked.Switch_disabled .Switch-Thumb::after {
    background-color: transparent;
}